.navbar {
  padding: 15px;
  background-color: grey;
}

.links {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  list-style: none;
  
  
  li {
    font-size: 18px;
    color: black;
  }
}
