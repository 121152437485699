.form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 20px;
  outline: 1px solid black;
  border: none;

  label {
    margin: 10px 0;
  }

  input {
    padding: 5px;
    border: none;
    outline: 1px solid black;
  }
  button {
    padding: 5px;
    cursor: pointer;
    margin: 15px 0;
    border: none;
    outline: 1px solid black;
  }
}

.link {
  text-decoration: none;
  color: black;
  margin-top: 5px;
  align-self: flex-end;
}
