.form {
  margin-bottom: 25px;
  padding: 30px;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 10px;
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.editWrapper {
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .edit {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: gainsboro;
    padding: 15px;
    outline: 1px solid black;
  }
}

.singleCard {
  outline: 1px solid black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  label {
    margin-bottom: 5px;
    margin-top: 5px;
    overflow: hidden;

    text-overflow: ellipsis;
    white-space: nowrap;
  }

  textarea {
    resize: none;
    padding: 5px;
    height: 200px;
    outline: 1px solid black;
    border: none;
    width: 100%;
  }

  input {
    padding: 5px;
  }
}

.btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  button {
    padding: 5px 15px;
    cursor: pointer;
    margin-top: 5px;
  }
}

.no {
  grid-column: -1/1;
  display: flex;
  flex-direction: column;

  h4 {
    align-self: center;
  }
}
