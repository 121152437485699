* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}


.app {

  display: flex;
  flex-direction: column;
}